import "./services.css"
import { Fade } from "react-awesome-reveal";
import { useContext } from "react";
import contrastContext from "../../context/contrastContext";



const Services = ({language}) => {

    const { contrast } = useContext(contrastContext);  

  return (
      <>
     {language === 'heb' &&
      <div className="services" style={{backgroundColor:`${contrast}`}}>
        <h1 className="servicesTitle">שירותים</h1>
        <div className="itemWrapper">
            <div className="sectionContainer">
                <Fade triggerOnce={true} duration={2000} direction="down">
                     <div className="serviceItem">
                        <img src="./assests/innovation.webp" alt="" className="serviceImg" />
                        <span className="itemText">הרכבות מכניות</span>        
                    </div>
                </Fade>
                <Fade triggerOnce={true} duration={2000}>
                    <p className="serviceDesc" style={{direction:"rtl"}}>כל סוגי ההרכבה, יכולת תכנון וייצור כלי הרכבה יעודיים.</p>
                </Fade>
            </div>
            <div className="verticalLine"></div>
            <div className="sectionContainer">
                <Fade triggerOnce={true} duration={2000} direction="down">
                    <div className="serviceItem">
                        <img src="./assests/battery.webp" alt="" className="serviceImg" />
                        <span className="itemText">ייצור כבלים</span>

                            
                    </div>
                </Fade>
                <Fade triggerOnce={true} duration={200}>
                    <p className="serviceDesc">.אפשרויות מגוונות לייצור וחיווט כבלים</p>
                </Fade>
            </div>
            <div className="verticalLine"></div>
            <div className="sectionContainer">
                <Fade triggerOnce={true} duration={2000} direction="down">
                    <div className="serviceItem">
                        <img src="./assests/package-delivery.webp" alt="" className="serviceImg" />
                            <span className="itemText"> אפשרויות אריזה</span>
                    </div>
                </Fade>
                <Fade triggerOnce={true} duration={2000}>
                    <p className="serviceDesc" style={{direction:"rtl"}}>אפשרויות אריזה מגוונות בתנאים סטריליים בסטנדרטים המחמירים ביותר.</p>
                 </Fade>
            </div>
        </div>
    </div>}
     {language === 'en' &&
      <div className="services" style={{backgroundColor:`${contrast}`}}>
             <h1 className="servicesTitle">SERVICES</h1>
                <div className="itemWrapper">
                    <div className="sectionContainer">
                        <Fade triggerOnce={true} duration={2000} direction="down">
                        <div className="serviceItem">
                            <img src="./assests/innovation.webp" width="90px" height="90px" alt="innovation" className="serviceImg" />
                            <h3 className="itemText">
                             Mechanical Assembly
                            </h3>
                        </div>
                        </Fade>
                        <Fade triggerOnce={true} duration={2000}>
                        <p className="serviceDesc" >All types of assembly, ability to design and manufacture dedicated assembly tools</p>
                        </Fade>
                    </div>
                    <div className="verticalLine"></div>
                    <div className="sectionContainer">
                        <Fade triggerOnce={true} duration={2000} direction="down">
                        <div className="serviceItem">
                            <img src="./assests/battery.webp" width="90px" height="90px" alt="battery" className="serviceImg" />
                            <h3 className="itemText">
                               Cable Manufacturing
                            </h3>
                        </div>
                    </Fade>
                    <Fade triggerOnce={true} duration={2000}>
                    <p className="serviceDesc">Diverse options for manufacturing and wiring cables.</p>
                    </Fade>
                    </div>
                    <div className="verticalLine"></div>
                    <div className="sectionContainer">
                    <Fade triggerOnce={true} duration={2000} direction="down">
                        <div className="serviceItem">
                            <img src="./assests/package-delivery.webp" width="90px" height="90px" alt="package" className="serviceImg" />
                            <h3 className="itemText">
                              Packing Options
                            </h3>
                            </div>
                            </Fade>
                        <Fade triggerOnce={true} duration={2000}>
                    <p className="serviceDesc" >Diverse packaging options under strict and sterile conditions.</p>
                    </Fade>
                </div>
             </div>
    </div>}
    </>
  )
}

export default Services