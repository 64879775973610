import "./navbar.css";
import {Link} from 'react-scroll';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBars,faX} from "@fortawesome/free-solid-svg-icons"
import {useState} from "react"
import { useContext } from "react";
import linksContext from "../../context/linksContext";
import contrastContext from "../../context/contrastContext";


const Navbar = ({language}) => {

  const [open,setOpen] = useState(false);
  const [exit,setExit] = useState(false);
  const [hamburger,setHamburger] = useState(true);
  const { highlightLinks } = useContext(linksContext);  

  const { contrast } = useContext(contrastContext);  


  return (
    <>
  {(language === 'heb') &&
   <div className="navbar" style={{backgroundColor:`${contrast}`}}>
      <div className="navContainer">
          <img src="assests/fhs-logo4.webp" alt="" className="logo" />
          <div className="navItems" style={highlightLinks ? {fontWeight:'bold'} : {fontWeight:'500'}}>
            <p className="navMenu">
              <Link to="details" spy={true} smooth={true} offset={-50} duration={500}>צור קשר</Link>
            </p>     
            <p className="navMenu">
              <Link to="companies" spy={true} smooth={true} offset={-150} duration={500}>לקוחותינו</Link>
            </p>
            <p className="navMenu">
              <Link to="services" spy={true} smooth={true} offset={-150} duration={500}>שירותים</Link>
            </p>
            <p className="navMenu">
              <Link to="iso" spy={true} smooth={true} offset={-150} duration={500}>תו תקן</Link>
            </p>
            <p className="navMenu">
             <Link to="about" spy={true} smooth={true} offset={-50} duration={500}>אודות</Link>
            </p>
          </div>         
          { hamburger &&
      <div className="hamburgerMenuIcon" >
        <FontAwesomeIcon icon={faBars} className="hamburgerMenu" onClick={() => {setOpen(true); setExit(true);setHamburger(false)}} />       
      </div>}
      {open &&
      <div className="sidebar">
        <div className="mobileNavItems" >
            <p className="navMenu">
              <Link to="about" spy={true} smooth={true} offset={-150} duration={500} onClick ={() =>{ setOpen(false);setExit(false); setHamburger(true) }}>אודות</Link>
            </p>
            <p className="navMenu">
              <Link to="iso" spy={true} smooth={true} offset={-150} duration={500} onClick={() =>{ setOpen(false); setExit(false); setHamburger(true) }}>תו תקן</Link>
            </p>
            <p className="navMenu">
              <Link to="services" spy={true} smooth={true} offset={-150} duration={500} onClick={() =>{ setOpen(false); setExit(false); setHamburger(true)}}>שירותים</Link>
            </p>
            <p className="navMenu">
              <Link to="companies" spy={true} smooth={true} offset={-150} duration={500} onClick={() =>{ setOpen(false); setExit(false); setHamburger(true)}}>לקוחותינו</Link>
            </p>
            <p className="navMenu">
              <Link to="details" spy={true} smooth={true} offset={-50} duration={500} onClick={() =>{ setOpen(false); setExit(false); setHamburger(true)}}>צור קשר</Link>
            </p>     
        </div>
       <div className="exitButton">
        <FontAwesomeIcon icon={faX} className="exitIcon" onClick={() =>{ setOpen(false);setHamburger(true)} }/> 
      </div>      
    </div>}
      </div>
  </div>}
  {(language === 'en') && 
  <div className="navbar" style={{backgroundColor:`${contrast}`}}>
      <div className="navContainer">
          <img src="assests/fhs-logo4.webp" alt="" className="logo" />
          <div className="navItems" style={highlightLinks ? {fontWeight:'bold'} : {fontWeight:'500'}}>
            <p className="navMenu">
              <Link to="aboutEng" spy={true} smooth={true} offset={-50} duration={500}>About Us</Link>
            </p>
            <p className="navMenu">
             <Link to="isoEng" spy={true} smooth={true} offset={-150} duration={500}>Standards</Link>
            </p>
            <p className="navMenu">
             <Link to="services" spy={true} smooth={true} offset={-150} duration={500}>Services</Link>
            </p>
            <p className="navMenu">
              <Link to="companies" spy={true} smooth={true} offset={-150} duration={500}>Our Clients</Link>
            </p>
            <p className="navMenu">
             <Link to="details" spy={true} smooth={true} offset={-50} duration={500}>Contact</Link>
            </p>     
          </div>
      </div>
      { hamburger &&
      <div className="hamburgerMenuIcon" >
        <FontAwesomeIcon icon={faBars} className="hamburgerMenu" onClick={() => {setOpen(true); setExit(true);setHamburger(false)}} />       
      </div>}
      {open &&
      <div className="sidebar">
        <div className="mobileNavItems" >
            <p className="navMenu">
              <Link to="aboutEng" spy={true} smooth={true} offset={-50} duration={500} onClick ={() =>{ setOpen(false);setExit(false); setHamburger(true) }}>About Us</Link>
            </p>
            <p className="navMenu">
              <Link to="isoEng" spy={true} smooth={true} offset={-150} duration={500} onClick={() =>{ setOpen(false); setExit(false); setHamburger(true) }}>Standards</Link>
            </p>
            <p className="navMenu">
              <Link to="services" spy={true} smooth={true} offset={-150} duration={500} onClick={() =>{ setOpen(false); setExit(false); setHamburger(true)}}>Services</Link>
            </p>
            <p className="navMenu">
              <Link to="companies" spy={true} smooth={true} offset={-150} duration={500} onClick={() =>{ setOpen(false); setExit(false); setHamburger(true)}}>Clients</Link>
            </p>
            <p className="navMenu">
              <Link to="details" spy={true} smooth={true} offset={-50} duration={500} onClick={() =>{ setOpen(false); setExit(false); setHamburger(true)}}>Contact</Link>
            </p>     
        </div>
       <div className="exitButton">
        <FontAwesomeIcon icon={faX} className="exitIcon" onClick={() =>{ setOpen(false);setHamburger(true)} }/> 
      </div>      
    </div>}
  </div>}
  
  </>
  )
}

export default Navbar;