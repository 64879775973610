import "./about.css"
import {Link} from "react-scroll";
import { Fade } from "react-awesome-reveal";
import 'animate.css';
import ReactPlayer from 'react-player/vimeo';
import { useContext } from "react";
import contrastContext from "../../context/contrastContext";


const About = () => {

    const { contrast } = useContext(contrastContext);  

    return (
        <>
          <div className="about" style={{backgroundColor:`${contrast}`}}>
                <h1 className="aboutTitle animate__animated animate__fadeIn">?מי אנחנו</h1>   
                <div className="itemsContainer">
                    <div className="aboutItems animate__animated animate__fadeInLeft">
                        <p dir="rtl" className="aboutText">
                            F.H.S מספקת פתרון כולל ואיכותי לכל צרכי הייצור שלכם במקום אחד 
                            ומאפשרת לכם להתמקד בתחומי הפעילות שלכם בפיתוח ושיווק המוצר הסופי ללא הסחות דעת .
                            אנו מבטיחים שהמוצרים שלכם יורכבו לפי הסטנדרטים המחמירים ביותר ובאיכות הגבוהה ביותר.
                            אנו נספק עבורכם את כול סוגי ההרכבה, הרכבה בחדר נקי, חיווט, ייצור כבלים, ציוד מתכלה, אריזה בתנאים סטריליים כדי ללוות אתכם עד לייצור סדרתי בריא ושוטף. וחשוב לא פחות, אנו ממוקמים כאן, בקרבתכם.
                            <br></br>
                            <br></br>
                            אנחנו מבצעים בדיקות מקיפות לחומרי הגלם הנרכשים דרככם כדי להבטיח שהמוצר הסופי יעמוד בסטנדרט הגבוהה ביותר.
                            אם אין ביכולתנו יכולת ייצור שדרושה לכם, אנחנו נעבוד איתכם כדי להביא את היכולת לפס הייצור שלנו.
                            המהנדסים שלנו יכולים לתכנן ולייצר כלי בדיקה והרכבה יעודים בסדנת הכלים בחברה. 
                            בדיקת המוצר היא חלק בלתי נפרד מתהליך הייצור, אנו נבטיח תפקוד תקין למוצר המוגמר שלכם בהתאם לדרישות מוגדרות.
                            אנו בF.H.S   מסוגלים לייצר עבורכם כל כמות שתידרש.
                            אז אם אתם מחפשים שותף אמיתי למערך הייצור שלכם, נשמח אם תראו בנו בחירה טבעית להגשמת החזון שלכם.<br></br><br></br> <b>רוצים לשמוע עוד?</b>
                        </p>
                        <Link to="details" spy={true} smooth={true} offset={-50} duration={500}>
                        <button className="aboutButton">
                           צרו קשר
                        </button>
                        </Link>
                        </div>
                    <div className="videoContainer">
                        <Fade triggerOnce direction="right">
                        <div className="videoItem">
                        <ReactPlayer controls={true} className='reactPlayer' url='https://vimeo.com/721236698' height='100%' width='100%'/>
                        </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </>
      )
}

export default About