
import { useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
} from "react-router-dom";
import Accessibility from "./components/accessibility/Accessibility";
import Home from "./pages/home/Home";
import {FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faWheelchairMove} from "@fortawesome/free-solid-svg-icons";
import fontContext from "./context/fontContext";
import linksContext from "./context/linksContext"
import titlesContext from "./context/titlesContext";
import openContext from "./context/openContext";
import themeContext from "./context/themeContext";
import languageContext from "./context/languageContext";
import contrastContext from "./context/contrastContext"



function App() {

  const [theme,setTheme] = useState("");
  const [fontSize,setFontSize] = useState(0);
  const [highlightLinks,setHighlightLinks] = useState(false);
  const [highlightTitles,setHighlightTitles] = useState(false);
  const [open,setOpen] = useState(false);
  const [language,setLanguage] = useState("en");
  const [contrast,setContrast] = useState("white");


  const font = {fontSize, setFontSize};
  const links = {highlightLinks,setHighlightLinks};
  const titles = {highlightTitles,setHighlightTitles};
  const siteTheme = {theme,setTheme};
  const acessOpen = {open,setOpen};
  const lang = {language,setLanguage};
  const mode = {contrast,setContrast};

  return (
    <contrastContext.Provider value={mode}>
    <languageContext.Provider value={lang}>
    <themeContext.Provider value={siteTheme}>
    <openContext.Provider value={acessOpen}>
    <titlesContext.Provider value={titles}>
    <linksContext.Provider value={links}>
    <fontContext.Provider value={font}>
      <div className={theme} style={{fontSize:16 + fontSize + 'px'}}>
          <div className="accessIcon" style={open ? {visibility:'hidden'} : {display:'visible'}}>
            <FontAwesomeIcon icon={faWheelchairMove} className="accessMenuIcon" onClick={() => {setOpen(true);}} />       
          </div>
          { open && <Accessibility/>}
        <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home/>}/>
        </Routes>
        </BrowserRouter>
      </div>
    </fontContext.Provider>
    </linksContext.Provider>
    </titlesContext.Provider>
    </openContext.Provider>
    </themeContext.Provider>
    </languageContext.Provider>
    </contrastContext.Provider>
  );
}

export default App;
