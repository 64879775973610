import { faEnvelope } from "@fortawesome/free-regular-svg-icons"
import { faLocationDot, faPhone } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { useState } from "react"
import "./details.css"
import emailjs from '@emailjs/browser';
import { Fade } from "react-awesome-reveal";



const Details = ({language}) => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [company,setCompany] = useState('');
    const [message, setMessage] = useState('');
    const [emailSent, setEmailSent] = useState(false);



    const submit = () => {
        if (name && email && message) {
            const serviceID = 'service_7kg045e';
            const templateID = 'template_kzx6whj';
            const publicKey = 'RteKmgX7hErA8slF4';
            const templateParams = {
                name,
                email,
                message,
                company
            };

            emailjs.send(serviceID, templateID, templateParams,publicKey)
                .then(response => console.log(response))
                .then(error => console.log(error));

            setName('');
            setEmail('');
            setMessage('');
            setCompany('');
            setEmailSent(true);
        } else {
            alert('Please fill in all fields.');
        }
    }
  return (
      <>
    {language === 'heb' &&
     <div className="details">
            <h1 className="contactTitle">צור קשר</h1>
        <div className="detailsContainer">
                <Fade triggerOnce delay={100}>
                <div className="detailsItems">
                    <div className="detailItem">
                            <FontAwesomeIcon icon={faLocationDot} className="detailIcon"/>
                            <span>ההדס 1, איזור תעשייה צפוני אור עקיבא </span>
                        </div>
                        <div className="detailItem">
                            <FontAwesomeIcon icon={faEnvelope} className="detailIcon"/>
                            <span>Office@fhs-ltd.com</span>
                        </div>
                        <div className="detailItem">
                            <FontAwesomeIcon icon={faPhone} className="detailIcon" />
                            <span>04-6593955</span>
                        </div>
                    </div>
                    </Fade>
                <Fade triggerOnce delay={200}>
             <div className="contactItems">
                <div className="contactForm">
                    <div className="msgWrapper">
                     <textarea className="contactTxt" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="הודעה"></textarea>
                    </div>
                    <div className="inputWrapper">
                        <input className="contactInput" value={name} onChange={(e)=> setName(e.target.value)} type="text" placeholder="שם" />
                        <input className="contactInput" value={email} onChange={(e) => setEmail(e.target.value)}type="email" placeholder="אימייל" />
                        <input className="contactInput" value={company} onChange={(e)=> setCompany(e.target.value)} type="text" placeholder="שם החברה" />
                    </div>
               
                </div>
                <button onClick={submit} className="contactBtn">שלח הודעה</button>
            </div>
            </Fade>
            </div>
            <Fade triggerOnce delay={200}>
            </Fade>
            {emailSent && <span className="contactMsg">!תודה על ההודעה , נהיה בקשר בהקדם</span>} 
    </div>}
    {language === 'en' &&
     <div className="details">
            <h1 className="contactTitle">CONTACT</h1>
        <div className="detailsContainer">
            <Fade triggerOnce delay={100}>
                <div className="detailsItems">
                    <div className="detailItem">
                            <FontAwesomeIcon style={{marginRight:"5px"}} icon={faLocationDot} className="detailIcon"/>
                            <span>HaHadas 1 , Northern Industrial Area Or Akiva</span>
                        </div>
                        <div  className="detailItem">
                            <FontAwesomeIcon style={{marginRight:"5px"}} icon={faEnvelope} className="detailIcon"/>
                            <span>Office@fhs-ltd.com</span>
                        </div>
                        <div className="detailItem">
                            <FontAwesomeIcon style={{marginRight:"5px"}} icon={faPhone} />
                            <span>04-6593955</span>
                        </div>
                    </div>
                </Fade>
            <Fade triggerOnce delay={200}>
             <div className="contactItems">
                <div className="contactForm">
                    <div className="msgWrapper">
                     <textarea className="contactTxt" style={{textAlign:"left"}} value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Message"></textarea>
                    </div>
                    <div className="inputWrapper">
                        <input className="contactInput" style={{textAlign:"left"}} value={name} onChange={(e)=> setName(e.target.value)} type="text" placeholder="Name" />
                        <input className="contactInput" style={{textAlign:"left"}} value={email} onChange={(e) => setEmail(e.target.value)}type="email" placeholder="Email" />
                        <input className="contactInput" style={{textAlign:"left"}} value={company} onChange={(e)=> setCompany(e.target.value)} type="email" placeholder="Company" />
                    </div>
               
                </div>
            </div>
            </Fade>
            </div>
            <Fade triggerOnce delay={200}>
            <button onClick={submit} className="contactBtn" >SEND MESSAGE</button>
            </Fade>
            {emailSent &&
             <h3 className="contactMsg">Thank you for your message, we will be in touch in no time!</h3>}
                           
            {emailSent &&
             <h3 className="contactMsgMobile">Thanks, we will be in touch in no time!</h3>}
                          
    </div>}
    </>
  )
}

export default Details