import "./photos.css"
import SimpleImageSlider from "react-simple-image-slider";

const images = [
    { url: "/assests/img1.webp" },
    { url: "/assests/img2.webp" },
    { url: "/assests/img3.webp" },
    { url: "/assests/img4.webp" },
    { url: "/assests/img5.webp" },
    { url: "/assests/img6.webp" },
    { url: "/assests/img7.webp" },
    { url: "/assests/img8.webp" },
    { url: "/assests/img9.webp" },
    { url: "/assests/img10.webp" },
  ];

  const imagesMobile = [
    { url: "/assests/img1m.webp" },
    { url: "/assests/img2m.webp" },
    { url: "/assests/img3m.webp" },
    { url: "/assests/img4m.webp" },
    { url: "/assests/img5m.webp" },
    { url: "/assests/img6m.webp" },
    { url: "/assests/img7m.webp" },
    { url: "/assests/img8m.webp" },
    { url: "/assests/img9m.webp" },
    { url: "/assests/img10m.webp" },
  ];

const Photos = ({language}) => {
  return (
      <div className="photos">
        <h1 className="photosTitle">{language=== "en" ? "A PEEK INSIDE" : "הצצה לבפנים"}</h1>
          <div className="photosContainer">
                <div className="photosItem">
                  <SimpleImageSlider 
                        width={800}
                        height={400}
                        images={images}
                        showBullets={true}
                        showNavs={true}
                        slideDuration={0.3}
                        loop={true}
                        autoPlay={false}
                        />
                </div>
                <div className="photosItemMobile">
                <SimpleImageSlider 
                      width={450}
                      height={250}
                      images={imagesMobile}
                      showBullets={true}
                      showNavs={true}
                      slideDuration={0.3}
                      loop={true}
                      autoPlay={false}
                      />
                </div>
                <div className="photosItemSmallMobile">
                <SimpleImageSlider 
                      width={350}
                      height={250}
                      images={imagesMobile}
                      showBullets={true}
                      showNavs={true}
                      slideDuration={0.3}
                      loop={true}
                      autoPlay={false}
                      />
                </div>
                <div className="photosItemTablets">
                <SimpleImageSlider 
                      width={600}
                      height={300}
                      images={images}
                      showBullets={true}
                      showNavs={true}
                      slideDuration={0.3}
                      loop={true}
                      autoPlay={false}
                      />
                </div>
              </div>
      </div>

  )
}

export default Photos