import "./home.css"
import Companies from "../../components/companies/Companies.jsx"
import Details from "../../components/details/Details.jsx"
import Header from "../../components/header/Header.jsx"
import HeaderEng from "../../components/headerEng/HeaderEng.jsx"
import Navbar from "../../components/navbar/Navbar"
import Services from "../../components/services/Services.jsx"
import About from "../../components/about/About.jsx"
import Iso from "../../components/iso/Iso.jsx"
import IsoEng from "../../components/isoEng/IsoEng.jsx"
import Photos from "../../components/photos/Photos.jsx"
import AboutEnglish from "../../components/aboutEng/AboutEnglish.jsx"
import { useContext } from "react";
import languageContext from "../../context/languageContext"


const Home = () => {


  const { language , setLanguage } = useContext(languageContext);  

  return (
    <>
    <div>
      <div className="home">
        <div className="languageCointaner">
          <div className="languageWrapper">
            <img src="/assests/usa-flag.webp" alt="" className="usaIcon" onClick={()=>setLanguage('en')} />
            <p className="language">|</p>
            <img src="/assests/israel-flag1.webp" alt="" className="israelIcon" onClick={()=>setLanguage('heb')}/>
          </div>   
        </div>

        <Navbar language={language} />
        {language === 'heb' && <Header language={language} />}
        {language === 'en' && <HeaderEng/>}
        {language === 'heb' && <About language={language}  />}
        {language === 'en' && <AboutEnglish language={language}  />}
        {language === 'heb' && <Iso language={language}  />}
        {language === 'en' && <IsoEng language={language}  />}
        <Services language={language} />
        <Photos language={language}  />
        <Companies language={language} />
        <Details language={language} />
      </div>
      </div>
      </>
  );
}

export default Home