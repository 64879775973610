
import "./headereng.css"
import {Link} from 'react-scroll';
import {Fade} from "react-awesome-reveal";

const Header = () => {

  return (
    <>
     <div className="headerEng">
        <div className="headerContainerEng">
            <div className="headerItemsEng">   
              <Fade direction="left" triggerOnce={true} duration={2000}>
                <h1 className="mainTitleEng">GOING AFTER YOUR <b>GOALS</b></h1> 
              </Fade>
              <Fade  direction="left" triggerOnce={true} duration={2000}>
                <p className="mainTextEng">
                  Our company provides high-quality solutions for all your production needs in one place and allows you to focus on the development and marketing of your product without distractions.
                </p>
              </Fade>
              <Fade direction="left" triggerOnce={true} duration={2000}>
                <Link to="services" spy={true} smooth={true} offset={-50} duration={500}>
                  <button className="headerButtonEng">READ MORE</button>
                </Link>
              </Fade>
              </div>
              <div className="imgContainerEng">
                <Fade triggerOnce direction="right" duration={2000}>
                  <img src="./assests/newScanner.webp" alt="Scanner" className="headerImgEng" />
                </Fade>
              </div>
          </div>
        </div>
       </>
  )
}

export default Header