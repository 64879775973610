import "./companies.css"
import { Fade } from "react-awesome-reveal";
import { useContext } from "react";
import contrastContext from "../../context/contrastContext";


const Companies = ({language}) => {

  const { contrast } = useContext(contrastContext);  


  return (
    <div className="companies" style={{backgroundColor:`${contrast}`}}>
            <h1 className="compTitle">{language === 'en' ? "OUR CLIENTS" : "בין לקוחותינו"}</h1> 
            <div className="compContainer">
                <div className="itemsWrapper">
                <Fade triggerOnce delay={100}>
                  <div className="companyItem">
                  <img src="./assests/xact-logo.webp" width="150px" height="55px" alt="xact" className="compImg" />
                  </div>
                  </Fade>
                  <Fade triggerOnce delay={200}>
                  <div className="companyItem">
                  <img src="./assests/Alma.webp" width="155px" height="55px" alt="alma" className="compImg" />
                  </div>
                  </Fade>
                  <Fade triggerOnce delay={300}>
                  <div className="companyItem">
                  <img src="./assests/hsl-logo.webp" width="290px" height="55px" alt="hsl" className="compImg" />
                  </div>
                  </Fade>
                  <Fade triggerOnce delay={400}>
                  <div className="companyItem">
                  <img src="./assests/rh-logo.webp" width="122px" height="55px" alt="rh" className="compImg" />
                  </div>
                  </Fade>
                  <Fade triggerOnce delay={500}>
                  <div className="companyItem">
                  <img src="./assests/haroshet.webp" width="157px" height="55px" alt="haroshet" className="compImg" />
                  </div>
                  </Fade>
                </div>
            </div>
            </div>
  )
}

export default Companies
