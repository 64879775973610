import "./accessibility.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faX,faEye,faLink, faMoon ,faSun, faTextHeight, faHeading,  faA } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import fontContext from "../../context/fontContext";
import linksContext from "../../context/linksContext";
import titlesContext from "../../context/titlesContext";
import openContext from "../../context/openContext";
import themeContext from "../../context/themeContext";
import languageContext from "../../context/languageContext"
import contrastContext from "../../context/contrastContext";



const Accessibility = () => {

  const {fontSize ,setFontSize} = useContext(fontContext);
  const {highlightLinks,setHighlightLinks} = useContext(linksContext);
  const {highlightTitles,setHighlightTitles} = useContext(titlesContext);
  const {setOpen} = useContext(openContext);
  const {theme,setTheme} = useContext(themeContext);
  const {language} = useContext(languageContext);
  const {setContrast} = useContext(contrastContext);
 
  const handleFontSize = (operation) =>{

    if(operation === "inc" && fontSize <= 2){
      setFontSize(prevSize => prevSize +2);
;
    }
    if(operation === "dec" && fontSize > -2){
      setFontSize(prevSize => prevSize -2);
    }
  }
  return (
    <div className="accessSidebar">
      <h1 className="accessMenuTitle">{language === "en" ? "Accessbility" : "נגישות"}</h1>
      <div className="accessMenuWrapper">
      <div className="accessItemsWrapper" >
        <div className="accessMenuItem" onClick={()=> setHighlightLinks(!highlightLinks)}>
          <FontAwesomeIcon icon={faLink} className="monoIcon" />
          <h3 className="accessTitle">{language === "en" ? "Highlight Links" : "הדגש קישורים"}</h3>
        </div>
        <div className="accessMenuItem" onClick={ ()=> theme === "" ? setTheme("mono") : setTheme("")}>
          <FontAwesomeIcon icon={faEye} className="monoIcon" />
          <h3 className="accessTitle">{language === "en"?  "Monochrome" : "מונוכרום"}</h3>
        </div>
        <div className="accessMenuItem" onClick={ ()=> setContrast("black")}>
          <FontAwesomeIcon icon={faMoon} className="monoIcon" />
          <h3 className="accessTitle">{language === "en" ? "Dark Contrast" : "ניגודיות כהה"}</h3>
        </div>
        <div className="accessMenuItem" onClick={ ()=> setContrast("white") }>
          <FontAwesomeIcon icon={faSun} className="monoIcon" />
          <h3 className="accessTitle">{language === "en" ? "Light Contrast" : "ניגודיות בהירה"}</h3>
        </div>
        <div className="accessMenuItem" onClick={ () => handleFontSize("inc")}>
          <FontAwesomeIcon icon={faTextHeight} className="monoIcon"  />
          <h3 className="accessTitle">{language === "en" ? "Increase Font" : "הגדלת פונט"}</h3>
        </div>
        <div className="accessMenuItem" onClick={ () => handleFontSize("dec")}>
          <FontAwesomeIcon icon={faTextHeight} className="monoIcon"  />
          <h3 className="accessTitle">{language === "en" ? "Decrease Font" : "הקטנת פונט"}</h3>
        </div>
        <div className="accessMenuItem">
          <FontAwesomeIcon icon={faHeading} className="monoIcon" onClick={ () => setHighlightTitles(!highlightTitles)} />
          <h3 className="accessTitle">{language === "en" ? "Highlight Titles" : "הדגשת כותרות"}</h3>
        </div>
        <div className="accessMenuItem">
          <FontAwesomeIcon icon={faA} className="monoIcon" />
          <h3 className="accessTitle">{language === "en" ? "Readable Font" : "כתב קריא"}</h3>
        </div>
      </div>
      <div className="buttonWrapper">
        <button className="cancelAccess" onClick={() => {setFontSize(0);setTheme("");setContrast("white")}}>
          Stop Accessibility 
      </button>
        </div>
  <div className="accessExitButton">
    <FontAwesomeIcon icon={faX} className="accessExitIcon" onClick={() => setOpen(false)}/> 
  </div>
    </div>
    </div>
    
  );

}



export default Accessibility