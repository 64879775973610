import "./header.css"
import {Link} from 'react-scroll';
import {Fade} from "react-awesome-reveal";


const Header = () => {
  return (
    <>
     <div className="header">
        <div className="headerContainer">
            <div className="headerItems">   
          <Fade className="headerReveal" triggerOnce={true} direction="left" duration={2000}>
              <h1 className="mainTitle">ללכת אחרי <b>היעדים</b> שלך</h1>
              <p className="mainText">
                F.H.S מספקת פתרונות לכל צרכי הייצור שלכם במקום אחד בסטנדרטים המחמירים ביותר ומאפשרת לכם להתמקד בפיתוח ובשיווק של המוצר שלכם ללא הסחות דעת.
              </p>
              <button  className="headerButton">
              <Link to="services" spy={true} smooth={true} offset={-50} duration={500}>קרא עוד</Link>
              </button>
          </Fade>
            </div>
              <div className="imgContainer">
            <Fade triggerOnce={true} direction="right" duration={2000}>
                <img src="./assests/newScanner.webp" alt="" className="headerImg"/>
            </Fade>
              </div>
        </div>
      </div>
    </>
  )
}

export default Header