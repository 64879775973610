import "./aboutenglish.css"
import {Link} from "react-scroll";
import { Fade } from "react-awesome-reveal";
import 'animate.css';
import ReactPlayer from 'react-player/vimeo';
import { useContext } from "react";
import contrastContext from "../../context/contrastContext";

const AboutEnglish = () => {

    const { contrast } = useContext(contrastContext);  

    return (
        <>
         <div className="aboutEng" style={{backgroundColor:`${contrast}`}}>
                <h1 className="aboutTitleEng animate__animated animate__fadeIn" >ABOUT US</h1>   
                <div className="itemsContainerEng">
                    <Fade cascade={true} className="aboutItemsEngReveal" direction="left" triggerOnce={true} duration={2000}>
                    <div className="aboutItemsEng">
                        <p className="aboutTextEng">
                             Our company provides a comprehensive and high-quality solution for all your production needs in one place. Our work allows you to focus on your business activity,
                              developing and marketing the final product without distractions.
                               We guarantee that your products will be assembled to the strictest standards and of the highest quality.
                                We will provide you with all types of assembly, clean room assembly, wiring, cable manufacturing, consumables, and packaging in sterile conditions to accompany you through healthy, continuous production. And just as important, we are located here, close to you.
                                <br></br><br></br>
                            We perform comprehensive tests for the raw materials purchased through you to ensure the final product meets the highest quality. If we do not have the production capacity you need, we will work with you to bring this to our production line. Our engineers can design and manufacture dedicated testing and assembly tools in the company's tool workshop.
                            Meanwhile, product inspection is an integral part of the production process, we will, therefore, ensure the proper functioning of your finished product under defined requirements.
                            Here at F.H.S, we can produce any quantity that you desire. So, if you are looking for a trustworthy partner for your production line, we would be glad for you to contact us. We will be a natural choice in fulfilling your vision.
                            <br></br><br></br> <b>Want to know more?</b>
                        </p>
                        <Link to="details" spy={true} smooth={true} offset={-50} duration={500}>
                        <button className="aboutButtonEng">
                           CONTACT
                        </button>
                        </Link>
                    </div>
                    </Fade>
                    <div className="videoContainerEng">
                        <Fade triggerOnce={true} duration={2000} direction="right">
                        <div className="videoItemEng">
                        <ReactPlayer controls={true} className='reactPlayer' url='https://vimeo.com/721236698' height='100%' width='100%'/>
                        </div>
                        </Fade>
                    </div>
                </div>
            </div>
        </>
      )
}

export default AboutEnglish