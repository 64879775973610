import "./isoeng.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { Fade } from "react-awesome-reveal";

const IsoEng = () => {
  return (
    <>
    <div className="isoEng">
      <h1 className="isoTitleEng">
          STANDARDS MARK
      </h1>
          <div className="isoItemsEng">
            <Fade triggerOnce delay={100}>
              <div className="isoItemEng">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck}/>
                  <span>ISO 13485.</span>
              </div>
              </Fade>
                <Fade triggerOnce delay={200}>
              <div className="isoItemEng">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck} />
                  <span>Authorized Supplier of Ministry of Defense.</span>
              </div>
              </Fade>
                <Fade triggerOnce delay={300}>
              <div className="isoItemEng">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck}/>
                  <span>Computerized Management System for all company activities.</span> 
              </div>
              </Fade>
                <Fade triggerOnce delay={400}>
              <div className="isoItemEng">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck}/>
                  <span>ISO 7 Classification Clean Room for packing and assembling with ISO3 Classification Laminar Cabinets.</span>
              </div>
              </Fade>
                <Fade triggerOnce delay={500}>
              <div className="isoItemEng">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck} />
                  <span>Performing tests and calibrations for all company's equipment.</span>
              </div>
              </Fade>
                <Fade triggerOnce delay={600}>
              <div className="isoItemEng">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck} />
                  <span>Monitoring the use of adhesives and materials without expiration in production.</span>
              </div>
              </Fade>
          </div>
      </div>
  </>

  )
}

export default IsoEng