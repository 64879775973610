import "./iso.css"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCheck } from "@fortawesome/free-solid-svg-icons"
import { Fade } from "react-awesome-reveal";

const iso = ({language}) => {
  return (
    <>
    <div className="iso">
           <h1 className="isoTitle">תו תקן</h1>
          <div className="isoItems">
                <Fade triggerOnce delay={100}>
              <div className="isoItem">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck} />
                  <span style={{fontWeight:"400"}}>ISO 13485.</span>
              </div>
                </Fade>
                <Fade triggerOnce delay={200}>
              <div className="isoItem">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck} />
                  <span style={{fontWeight:"400"}}>ספק מורשה משרד הבטחון.</span>
              </div>
              </Fade>
              <Fade triggerOnce delay={300}>
              <div className="isoItem">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck}/>
                  <span style={{fontWeight:"400"}}>מערכת ניהול ממוחשבת לכל פעילות החברה.</span>
              </div>
              </Fade>
                <Fade  triggerOnce delay={400}>
              <div className="isoItem">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck}/>
                  <span style={{fontWeight:"400"}}>חדר נקי להרכבות ואריזה ברמת ניקיון של ISO7 עם תאים למינאריים ברמת נקיון של ISO3.</span>  
              </div>
                </Fade>
                <Fade triggerOnce delay={500}>
              <div className="isoItem">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck} />
                  <span style={{fontWeight:"400"}}>ביצוע בדיקות וכיול בזמן לכל הציוד שברשותנו.</span>    
              </div>
                </Fade>
                <Fade triggerOnce delay={600}>
              <div className="isoItem">
                  <FontAwesomeIcon className="isoIcon" icon={faCheck} />
                  <span style={{fontWeight:"400"}}>מעקב שימוש בדבקים וחומרים - ללא פג תוקף בייצור.</span>
              </div>
            </Fade>   
          </div>
          
      </div>
  </>

  )
}

export default iso